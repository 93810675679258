import React, { useEffect } from "react";
import Slider from "react-slick";
import { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StaticTestimonials = (...props) => {

  const [slider, setSlider] = useState();

  const renderArrows = () => {
    return (
      <div className="arrows">
        <div className="container">
          <div
            onClick={() => slider.slickNext()}
            className="next cursor-pointer"
          >
            <span className="pe-7s-angle-right"></span>
          </div>
          <div
            onClick={() => slider.slickPrev()}
            className="prev cursor-pointer"
          >
            <span className="pe-7s-angle-left"></span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section
      className={`testimonials ${
        props[0].withIMG
          ? "section-padding bg-img"
          : props[0].withCOLOR
          ? "section-padding back-color"
          : props[0].noPadding
          ? ""
          : "section-padding"
      } ${props.classText ? props.classText : ""} static-testimonials`}
      style={{
        backgroundImage: `${
          props[0].withIMG ? "url(/img/testim-img.jpg)" : "none"
        }`,
      }}
    >
      {/* {props.showHead && ( */}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center content">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                Testimonials
              </h6>
              <h4 className="wow color-font animated">
                We love our clients from all over the world.
              </h4>
            </div>
          </div>
        </div>
      </div>
      {/*  )} */}

      <div className="container-fluid position-re">
        <div className="row wow fadeInUp" data-wow-delay=".5s">
          <div className="col-lg-12">
            <Slider
              className="slic-item"
              {...{
                ref: (c) => setSlider(c),
                dots: false,
                infinite: true,
                arrows: true,
                centerMode: true,
                autoplay: false,
                rows: 1,
                slidesToScroll: 1,
                slidesToShow: 3,
                centerPadding: 0,
                responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 1,
                      centerMode: false,
                    },
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                      centerMode: false,
                    },
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      centerMode: false,
                    },
                  },
                ],
              }}
            >
                <div className="item">
                <div className="info valign">
                    <div className="cont">
                    <div className="author">
                        {/* <div className="img">
                        <img src="/img/testimonials/MalachyErebor.jpg" className="aspect-ratio" loading ="lazy"
                            alt="Malachy Erebor"
                        />
                        </div> */}
                        <h6 className="author-name color-font">
                        Amit Bansal
                        </h6>
                        <span className="author-details">
                        Owner of ABC Business Coach
                        </span>
                    </div>
                    </div>
                </div>
                <p>Evolvan has transformed our digital presence! Their services are top-notch and have significantly boosted our online visibility.</p>
                </div>

                <div className="item">
                <div className="info valign">
                    <div className="cont">
                    <div className="author">
                        {/* <div className="img">
                        <img src="/img/testimonials/KevinCostello.jpg" className="aspect-ratio" loading ="lazy"
                            alt="Kevin Costello"
                        />
                        </div> */}
                        <h6 className="author-name color-font">
                        Dr. Rajeev 
                        </h6>
                        <span className="author-details">
                        Owner RK Dental
                        </span>
                    </div>
                    </div>
                </div>
                <p>Thanks to the team at Evolvan, our business has seen remarkable growth. Their digital marketing strategies are highly effective and result-driven</p>
                </div>

                <div className="item">
                <div className="info valign">
                    <div className="cont">
                    <div className="author">
                        {/* <div className="img">
                        <img src="/img/testimonials/MikeMoran.jpg" className="aspect-ratio" loading ="lazy"
                            alt="Mike Moran"
                        />
                        </div> */}
                        <h6 className="author-name color-font">
                        DT Jasmeet Kaur 
                        </h6>
                        <span className="author-details">
                        MindFullEating
                        </span>
                    </div>
                    </div>
                </div>
                <p>Working with the organization has been a game-changer for us. Their team's expertise and dedication have exceeded our expectations.</p>
                </div>

                <div className="item">
                <div className="info valign">
                    <div className="cont">
                    <div className="author">
                        {/* <div className="img">
                        <img src="/img/testimonials/EricBrent.jpg" className="aspect-ratio" loading ="lazy"
                            alt="Eric Brent"
                        />
                        </div> */}
                        <h6 className="author-name color-font">
                        Kanhiya Adv
                        </h6>
                        {/* <span className="author-details">
                        CEO at HappyCow
                        </span> */}
                    </div>
                    </div>
                </div>
                <p>Evolvan helped us reach our target audience with precision. We're thrilled with the results and highly recommend their services.</p>
                </div>

                <div className="item">
                <div className="info valign">
                    <div className="cont">
                    <div className="author">
                        {/* <div className="img">
                        <img src="/img/testimonials/AngusLancaster.jpg" className="aspect-ratio" loading ="lazy"
                            alt="Angus Lancaster"
                        />
                        </div> */}
                        <h6 className="author-name color-font">
                        Guneet Talwar 
                        </h6>
                        <span className="author-details">
                        Talwar
                        </span>
                    </div>
                    </div>
                </div>
                <p>Choosing Evolvan Info Solution for our digital marketing needs was the best decision we made. Their professionalism and commitment to success are unmatched.</p>
                </div>

                <div className="item">
                <div className="info valign">
                    <div className="cont">
                    <div className="author">
                        {/* <div className="img">
                        <img src="/img/testimonials/AngusLancaster.jpg" className="aspect-ratio" loading ="lazy"
                            alt="Angus Lancaster"
                        />
                        </div> */}
                        <h6 className="author-name color-font">
                        Navjot Singh 
                        </h6>
                        <span className="author-details">
                        Eros Trading
                        </span>
                    </div>
                    </div>
                </div>
                <p>The team at Evolvan not only delivered results but also provided valuable insights throughout our collaboration. We're extremely satisfied with their services.</p>
                </div>

                <div className="item">
                <div className="info valign">
                    <div className="cont">
                    <div className="author">
                        {/* <div className="img">
                        <img src="/img/testimonials/AngusLancaster.jpg" className="aspect-ratio" loading ="lazy"
                            alt="Angus Lancaster"
                        />
                        </div> */}
                        <h6 className="author-name color-font">
                        Natasha Khanna
                        </h6>
                        <span className="author-details">
                        Banyan Art Gallery
                        </span>
                    </div>
                    </div>
                </div>
                <p>The team at Evolvan understands our business goals and tailors their strategies accordingly. Their personalized approach sets them apart in the digital marketing industry.</p>
                </div>

                {/* <div className="item">
                <div className="info valign">
                    <div className="cont">
                    <div className="author">
                        <div className="img">
                        <img src="/img/testimonials/AngusLancaster.jpg" className="aspect-ratio" loading ="lazy"
                            alt="Angus Lancaster"
                        />
                        </div>
                        <h6 className="author-name color-font">
                        Angus Lancaster 
                        </h6>
                        <span className="author-details">
                        Owner at WaterWalkerz
                        </span>
                    </div>
                    </div>
                </div>
                <p>Evolvan Info Solution's expertise in digital marketing has significantly contributed to our business growth. We're grateful for their partnership and continued support.</p>
                </div> */}

                {/* <div className="item">
                <div className="info valign">
                    <div className="cont">
                    <div className="author">
                        <div className="img">
                        <img src="/img/testimonials/AngusLancaster.jpg" className="aspect-ratio" loading ="lazy"
                            alt="Angus Lancaster"
                        />
                        </div>
                        <h6 className="author-name color-font">
                        Angus Lancaster 
                        </h6>
                        <span className="author-details">
                        Owner at WaterWalkerz
                        </span>
                    </div>
                    </div>
                </div>
                <p>Evolvan Info Solution's services have exceeded our expectations in every aspect. We're delighted with the outcome and look forward to continued success with their support.</p>
                </div> */}
            </Slider>
          </div>
        </div>
        {renderArrows()}
      </div>
    </section>
  );
};

export default StaticTestimonials;
