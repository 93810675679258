import { useState } from "react";
import React from "react";
import { gql, useMutation, ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";


const LandingPageForm=()=>{

    const APOLLO_CLIENT = new ApolloClient({
        uri: process.env.GATSBY_API_URL,
        cache: new InMemoryCache()
      });

      const FormData=()=>{
      const messageRef = React.useRef(null);
      const [nameValue, setNameValue] = useState('')
      const [emailValue, setEmailValue] = useState('')
      const [contactValue, setContactValue] = useState("");
      const [businessNameValue, setBusinessNameValue]=useState('');

      const CREATE_LANDING_PAGE_APPLICATION = gql`
      mutation createLandingPageSubmissionMutation($clientMutationId: String!, $name: String!, $email_address: String!, $phone_number: String!, $business:String!) {
        createLandingPageSubmission(input: {clientMutationId: $clientMutationId, name: $name, email_address: $email_address, phone_number: $phone_number, business: $business }) {
          success
          data
        }
      }
    `;
    const [createLandingPageSubmission, { data, loading, error }] = useMutation(CREATE_LANDING_PAGE_APPLICATION);

    const handleSubmit =async(event)=>{
      event.preventDefault();

  
      try{
        const result=await createLandingPageSubmission({
      variables:{
        clientMutationId: "abc123",
        name: nameValue,
        email_address: emailValue,
        phone_number: contactValue,
        business: businessNameValue
      }
    });
      // console.log(result.data.createLandingPageSubmission);
    }catch(error){
      console.error("Error creating Landing Page Submission",error)
    }
    }


    return(
        <section loading="lazy" style={{backgroundImage:'url(/img/patrn.svg)'}} className="contact section-padding bg-img call-action">
        <div className="container">
          <div className="row justify-content-center">
            <div id="contact" className="col-lg-6">
              <div className="form md-mb50 content">
                <h4 className="color-font wow mb-50">Schedule a free call back</h4>
                
                  <form id="contact-form" onSubmit={handleSubmit}>
                    
                    <div className="messages" ref={messageRef}></div>
                    <div className="controls">
                      <div className="form-group">
                        <input
                          id="name"
                          type="text"
                          name="name"
                          placeholder="Name"
                          required="required"
                          onChange={event => {
                            setNameValue(event.target.value)
                          }}
                        />
                      </div>
                      
                      <div className="form-group">
                        <input
                          id="phone_number"
                          type="number"
                          name="phone_number"
                          placeholder="Mobile Number"
                          required="required"
                          onChange={event => {
                            setContactValue(event.target.value)
                          }}
                        />
                      </div>
                      
                      <div className="form-group">
                        <input
                          id="email_address"
                          type="email"
                          name="email_address"
                          placeholder="Email"
                          onChange={event => {
                            setEmailValue(event.target.value)
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <input
                          id="business"
                          type="text"
                          name="business"
                          placeholder="Business Name"
                          onChange={event => {
                            setBusinessNameValue(event.target.value)
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <input
                        type="text"
                        id="city"
                        name="city"
                        placeholder="City"
                        required="required"
                        onChange={event => {
                            setCityValue(event.target.value)
                        }}
                      />
                    </div> */}
                    {/* <div className="form-group">
                      <input
                        type="text"
                        id="state"
                        name="state"
                        placeholder="State"
                        required="required"
                        onChange={event => {
                            setStateValue(event.target.value)
                        }}
                      />
                    </div> */}

                    <button type="submit" className="nb butn bord curve mt-30">
                      <span>Call me back</span>
                    </button>
                  </form>
                  
                  <div>
                  {loading && <p>Loading ...</p>}
                  {error && (<p>An unknown error has occured, please try again later ...</p>)}
                  {data && data.createLandingPageSubmission.success && <p>Message sent successfully!</p>}
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    );
    }

    return (
      <ApolloProvider client={APOLLO_CLIENT}>
        <FormData />
      </ApolloProvider>
    );

}

export default LandingPageForm; 