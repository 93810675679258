import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CustomArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "gray" }}
      onClick={onClick}
    />
  );
}

function Packslider() {
  const sliderRef=React.useRef(null);
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 1 ,
    initialSlide: 0,
    speed: 500,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite:true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite:true
        }
      }
    ]
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
    <div className="container d-flex flex-column">
      <div className="flex-col text-controls content">
          <h4 className="wow color-font animated mb-10">Why use our One-time pack?</h4>
        <div className="slider-arrows d-flex justify-content-end">

          </div>
      </div>

      <div className="flex-col slider-container">
        <div className="slider-content">
          <div className="info-slides">
            <Slider {...settings} ref={sliderRef}>
                <div className="item content">
                  <h4 className="wow color-font animated">1.Cost-Effective Solution</h4>
                  <p>Our one-time pack offers a cost-effective solution compared to ongoing monthly subscriptions, making it suitable for businesses with budget constraints.</p>
                </div>

                <div className="item content">
                  <h4 className="wow color-font animated">2.Tailored to Your Needs</h4>
                  <p>Each one-time pack is custom-tailored to your specific business goals, ensuring that you receive targeted solutions that align with your objectives.</p>
                </div>

                <div className="item content">
                  <h4 className="wow color-font animated">3.Quick Results</h4>
                  <p>With our one-time pack, you can expect to see quick results as we implement strategies designed to boost your online presence efficiently.</p>
                </div>

                <div className="item content">
                  <h4 className="wow color-font animated">4.No Long-Term Commitment</h4>
                  <p>Unlike long-term contracts, our one-time pack requires no long-term commitment, giving you the flexibility to engage with our services as needed.</p>
                </div>

                <div className="item content">
                  <h4 className="wow color-font animated">5.Focused Approach</h4>
                  <p>Our one-time pack allows us to focus our efforts on delivering maximum impact within a specific timeframe, ensuring that your goals are met effectively.</p>
                </div>

                <div className="item content">
                  <h4 className="wow color-font animated">6.Professional Expertise</h4>
                  <p>Benefit from the expertise of our seasoned professionals who will guide you through every step of the process to achieve your desired outcomes.</p>
                </div>

                <div className="item content">
                  <h4 className="wow color-font animated">7.Branded Online Presence</h4>
                  <p>Establish a strong and consistent branded online presence that resonates with your target audience, driving engagement and loyalty.</p>
                </div>

                <div className="item content">
                  <h4 className="wow color-font animated">8.Transparent Process</h4>
                  <p>Our transparent process ensures that you are fully informed and involved throughout the duration of the project, providing peace of mind and confidence in the results</p>
                </div>
            </Slider>
            <a className="color-font slider-btn prev-arrow mx-2" onClick={goToPrev}>
              {"<"}
            </a>
            <a className="color-font slider-btn next-arrow mx-2" onClick={goToNext}>
              {">"}
            </a>
          </div>
        </div>
      </div>
    </div>
    </>


  );
}

export default Packslider;
