import React from "react";
import metadata from "../../../metadata.json";

const SEO = ({
  title,
  description,
  author,
  ogurl,
  ogtype,
  ogtitle,
  ogdescription,
  ogimage,
  ogsitename,
  fbappid,
  shouldhideMetaTags,
  twittercard,
  twittertitle,
  twittersite,
  twitterimage,
  twitterdescription,
  canonical,
  publisher,
  detail,
  pathname,
  keywords,
}) => {
  const {
    twittercreator,
    georegion,
    geoplacename,
    geoposition,
    ICBM,
    alternate,
    hreflang,
    ...restMetadata
  } = metadata;
  const seo = {
    title: title || restMetadata.title,
    description: description || restMetadata.description,
    author: author || restMetadata.author,
    ogurl: `${ogurl}${pathname || ""}`,
    ogimage: `${ogimage}${pathname || ""}`,
    ogsitename: ogsitename || restMetadata.ogsitename,
    ogtitle: ogtitle || restMetadata.ogtitle,
    ogtype: ogtype || restMetadata.ogtype,
    fbappid: fbappid || restMetadata.fbappid,
    ogdescription: ogdescription || restMetadata.ogdescription,
    twittercard: twittercard || restMetadata.twittercard,
    twitterdescription: twitterdescription || restMetadata.twitterdescription,
    twittertitle: twittertitle || restMetadata.twittertitle,
    twittersite: `${twittersite}${pathname || ""}`,
    twitterimage: `${twitterimage}${pathname || ""}`,
    twittercreator: twittercreator || restMetadata.twittercreator,
    georegion: georegion || restMetadata.georegion,
    geoplacename: geoplacename || restMetadata.geoplacename,
    geoposition: geoposition || restMetadata.geoposition,
    ICBM: ICBM || restMetadata.restMetadata.ICBM,
    canonical: `${canonical}${pathname || ""}`,
    publisher: `${publisher}${pathname || ""}`,
    alternate: `${alternate}${pathname || ""}`,
    hreflang: hreflang || restMetadata.hreflang,
    detail: detail || restMetadata.detail,
    keywords: keywords || restMetadata.keywords,

    ...restMetadata,
  };

  return (
    <>
      <title>{title ?? seo.title}</title>
      <meta name="description" content={description ?? seo.description} />
      <meta name="author" content={author ?? seo.author} />
      <meta property="og:url" content={ogurl ?? seo.ogurl} />
      <meta property="og:type" content={ogtype ?? seo.ogtype} />
      <meta property="og:title" content={title ?? seo.ogtitle} />
      <meta
        property="og:description"
        content={ogdescription ?? seo.ogdescription}
      />
      <meta property="og:image" content={ogimage ?? seo.ogimage} />
      {!shouldhideMetaTags && (
        <>
          <meta
            property="og:site_name"
            content={ogsitename ?? seo.ogsitename}
          />
          <meta property="fb:app_id" content={fbappid ?? seo.fbappid} />
        </>
      )}
      <meta name="twitter:card" content={twittercard ?? seo.twittercard} />
      <meta
        name="twitter:description"
        content={twitterdescription ?? seo.twitterdescription}
      />
      <meta name="twitter:title" content={twittertitle ?? seo.twittertitle} />
      <meta name="twitter:site" content={twittersite ?? seo.twittersite} />
      <meta name="twitter:image" content={twitterimage ?? seo.twitterimage} />
      <meta name="twitter:creator" content={seo.twittercreator} />
      {!shouldhideMetaTags && (
        <>
          <meta name="geo.region" content={seo.georegion} />
          <meta name="geo.placename" content={seo.geoplacename} />
          <meta name="geo.position" content={seo.geoposition} />
          <meta name="ICBM" content={seo.ICBM} />
        </>
      )}
      {/* <link rel="canonical" href={`${canonical}`} /> */}
      <link rel="canonical" href={canonical ?? seo.canonical} />
      {/* <link rel="canonical" href={`${seo.canonical || ""}${canonical}`} /> */}

      <link rel="publisher" href={seo.publisher} />
      {!shouldhideMetaTags && (
        <>
          <link rel="alternate" href={seo.alternate} hreflang={seo.hreflang} />
        </>
      )}
      <meta name="detail" content={detail ?? seo.detail} />
      <meta name="keywords" content={keywords ?? seo.keywords} />
    </>
  );
};

export default SEO;
