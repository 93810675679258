import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

const InfiniteCarousel = () => {
    const containerRef = useRef(null);
    const [maxNodes, setMaxNodes] = useState(0);

    const appendNodes = (nodesToAdd) => {
        // console.log("Append Nodes Entered");
        const appendCount = nodesToAdd;
        for (let i = 0; i < appendCount; i++) {
            const clone1 = document.querySelector(".logos-slide1")?.cloneNode(true);
            const clone2 = document.querySelector(".logos-slide2")?.cloneNode(true);
            const clone3 = document.querySelector(".logos-slide3")?.cloneNode(true);
            const clone4 = document.querySelector(".logos-slide4")?.cloneNode(true);
            const clone5 = document.querySelector(".logos-slide5")?.cloneNode(true);
            const clone6 = document.querySelector(".logos-slide6")?.cloneNode(true);


            if (clone1 || clone2 || clone3 || clone4 || clone5 || clone6) {
                document.querySelector(".logos1").appendChild(clone1);
                document.querySelector(".logos2").appendChild(clone2);
                document.querySelector(".logos3").appendChild(clone3);
                document.querySelector(".logos4").appendChild(clone4);
                document.querySelector(".logos5").appendChild(clone5);
                document.querySelector(".logos6").appendChild(clone6);

                clone1.classList.add("js-appended-slide");
                clone2.classList.add("js-appended-slide");
                clone3.classList.add("js-appended-slide");
                clone4.classList.add("js-appended-slide");
                clone5.classList.add("js-appended-slide");
                clone6.classList.add("js-appended-slide");

                // console.log("child Added");
            }
            else{
                // console.log("failed to clone nodes");
            }
            }
        }


    const removeNodes = (nodesToRemove) => {
        // console.log("Remove Nodes Entered");
        while (nodesToRemove > 0) {
            const logos1 = document.querySelector(".logos1");
            const logos2 = document.querySelector(".logos2");
            const logos3 = document.querySelector(".logos3");
            const logos4 = document.querySelector(".logos4");
            const logos5 = document.querySelector(".logos5");
            const logos6 = document.querySelector(".logos6");

            const lastLogosSlide1=logos1.querySelector(".logos-slide1:last-child");
            const lastLogosSlide2=logos2.querySelector(".logos-slide2:last-child");
            const lastLogosSlide3=logos3.querySelector(".logos-slide3:last-child");
            const lastLogosSlide4=logos4.querySelector(".logos-slide4:last-child");
            const lastLogosSlide5=logos5.querySelector(".logos-slide5:last-child");
            const lastLogosSlide6=logos6.querySelector(".logos-slide6:last-child");

            if (lastLogosSlide1) {
                logos1.removeChild(lastLogosSlide1);
                // console.log("Removed .logos-slide1:",lastLogosSlide1);
            }
            else{
                // console.log("No .logos-slide1 to remove");
            }

            if (lastLogosSlide2) {
                logos2.removeChild(lastLogosSlide2);
                // console.log("Removed .logos-slide2",lastLogosSlide2);
            }
            else{
                // console.log("No .logos-slide2 to remove");
            }

            if (lastLogosSlide3) {
                logos3.removeChild(lastLogosSlide3);
                // console.log("Removed .logos-slide3",lastLogosSlide3);
            }
            else{
                // console.log("No .logos-slide3 to remove");
            }

            if (lastLogosSlide4) {
                logos4.removeChild(lastLogosSlide4);
                // console.log("Removed .logos-slide4",lastLogosSlide4);
            }
            else{
                // console.log("No .logos-slide4 to remove");
            }

            if (lastLogosSlide5) {
                logos5.removeChild(lastLogosSlide5);
                // console.log("Removed .logos-slide5",lastLogosSlide5);
            }
            else{
                // console.log("No .logos-slide5 to remove");
            }

            if (lastLogosSlide6) {
                logos6.removeChild(lastLogosSlide6);
                // console.log("Removed .logos-slide6",lastLogosSlide6);
            }
            else{
                // console.log("No .logos-slide6 to remove");
            }

            nodesToRemove--;
        }
    };
    
    

    useEffect(() => {
        const sizeOnLoad = () => {
            // console.log("Size on Load Executed");
            const screenwidth = window.innerWidth;
            let maxNodes = 0;
            if (screenwidth >= 2560) {
                maxNodes = 6;
                // console.log("Screen Width: 2560");
            } else if (screenwidth >= 1440) {
                maxNodes = 4;
                // console.log("Screen Width: 1440");
            } else {
                maxNodes = 3;
                // console.log("Screen Width: Less than 1440");
            }

            appendNodes(maxNodes);
            setMaxNodes(maxNodes);
        };

        sizeOnLoad();

        // Attach resize event listener
        const handleResize=()=>{
            // console.log("Inside Resize Function");
            const screenwidth = window.innerWidth;
            let newMaxNodes = 0;
            if (screenwidth >= 2560) {
                newMaxNodes = 6;
                // console.log("Screen Width: 2560");
            } else if (screenwidth >= 1440) {
                newMaxNodes = 4;
                // console.log("Screen Width: 1440");
            } else {
                newMaxNodes = 3;
                // console.log("Screen Width: Less than 1440");
            }
            setMaxNodes(prevMaxNodes => {
                // console.log(prevMaxNodes,"Max Nodes in resize function")
                if (newMaxNodes > prevMaxNodes) {
                    // console.log("newMaxNodes is greater than previous nodes");
                    const nodesToAdd = newMaxNodes - prevMaxNodes;
                    appendNodes(nodesToAdd);
                } else if (newMaxNodes < prevMaxNodes) {
                    // console.log("Previous nodes is greater than newMaxnodes");

                    const nodesToRemove = prevMaxNodes - newMaxNodes;
                    removeNodes(nodesToRemove);
                }
                return newMaxNodes;
            });
            

            setMaxNodes(newMaxNodes)
        }
        window.addEventListener('resize', handleResize);
    }, []);


        return(
                <div class="newbody">
                    <div ref={containerRef}>
                    <div class="logos1 logo">
                        <div class="logos-slide1">
                            <img class="grid__img1" src="/img/infinite-carousel/1-row/Artboard-2.webp" srcSet="/img/infinite-carousel/1-row/2xArtboard-2.webp 2x" alt="02" />
                            <img class="grid__img1" src="/img/infinite-carousel/1-row/Artboard-3.webp" srcSet="/img/infinite-carousel/1-row/Artboard-32x.webp 2x" alt="03" />
                            <img class="grid__img1" src="/img/infinite-carousel/1-row/Artboard-4.webp" srcSet="/img/infinite-carousel/1-row/Artboard-42x.webp 2x" alt="04" />
                            <img class="grid__img1" src="/img/infinite-carousel/1-row/Artboard-5.webp" srcSet="/img/infinite-carousel/1-row/Artboard-52x.webp 2x" alt="05" />
                            <img class="grid__img1" src="/img/infinite-carousel/1-row/Artboard-6.webp" srcSet="/img/infinite-carousel/1-row/Artboard-62x.webp 2x" alt="06" />
                            <img class="grid__img1" src="/img/infinite-carousel/1-row/Artboard-7.webp" srcSet="/img/infinite-carousel/1-row/2xArtboard-7.webp 2x" alt="07" />
                            <img class="grid__img1" src="/img/infinite-carousel/1-row/Artboard-1.webp" srcSet="/img/infinite-carousel/1-row/Artboard-12x.webp 2x" alt="08" />
                        </div>
                    </div>
                
                    <div class="logos2 logo">
                        <div class="logos-slide2">
                            <img class="grid__img2" src="/img/infinite-carousel/2-row/9.webp" srcSet="/img/infinite-carousel/2-row/2x9.webp" alt="01" />
                            <img class="grid__img2" src="/img/infinite-carousel/2-row/Behind-the-Scenes-Templates.webp" srcSet="/img/infinite-carousel/2-row/2xBehind-the-Scenes-Templates.webp 2x" alt="02" />
                            <img class="grid__img2" src="/img/infinite-carousel/2-row/Branded-Quote-Templates.webp" srcSet="/img/infinite-carousel/2-row/2xBranded-Quote-Templates.webp 2x" alt="03" />
                            <img class="grid__img2" src="/img/infinite-carousel/2-row/Event-Templates.webp" srcSet="/img/infinite-carousel/2-row/2xEvent-Templates.webp 2x" alt="04" />
                            <img class="grid__img2" src="/img/infinite-carousel/2-row/Poll_Question-Templates.webp" srcSet="/img/infinite-carousel/2-row/2x_Poll_Question-Templates.webp 2x" alt="05" />
                            <img class="grid__img2" src="/img/infinite-carousel/2-row/Product-Showcase-Templates.webp" srcSet="/img/infinite-carousel/2-row/2xProduct-Showcase-Templates.webp 2x" alt="06" />
                            <img class="grid__img2" src="/img/infinite-carousel/2-row/Tips_Advice-Templates.webp" srcSet="/img/infinite-carousel/2-row/2xTestimonial-Templates.webp 2x" alt="08" />
                            <img class="grid__img2" src="/img/infinite-carousel/2-row/Testimonial-Templates.webp" srcSet="/img/infinite-carousel/2-row/2xTips_Advice-Templates.webp 2x" alt="07" />
                        </div>
                    </div> 
                
                    <div class="logos3 logo">
                        <div class="logos-slide3">
                            <img class="grid__img3" src="/img/infinite-carousel/3-row/1.webp" srcSet="/img/infinite-carousel/3-row/1@2x.webp 2x" alt="01"/>
                            <img class="grid__img3" src="/img/infinite-carousel/3-row/2.webp" srcSet="/img/infinite-carousel/3-row/2@2x.webp 2x" alt="02"/>
                            <img class="grid__img3" src="/img/infinite-carousel/3-row/3.webp" srcSet="/img/infinite-carousel/3-row/3@2x.webp 2x" alt="03"/>
                            <img class="grid__img3" src="/img/infinite-carousel/3-row/4.webp" srcSet="/img/infinite-carousel/3-row/4@2x.webp 2x" alt="04"/>
                            <img class="grid__img3" src="/img/infinite-carousel/3-row/5.webp" srcSet="/img/infinite-carousel/3-row/5@2x.webp 2x" alt="05"/>
                            <img class="grid__img3" src="/img/infinite-carousel/3-row/6.webp" srcSet="/img/infinite-carousel/3-row/6@2x.webp 2x" alt="06"/>
                            <img class="grid__img3" src="/img/infinite-carousel/3-row/7.webp" srcSet="/img/infinite-carousel/3-row/7@2x.webp 2x" alt="07"/>
                            <img class="grid__img3" src="/img/infinite-carousel/3-row/1.webp" srcSet="/img/infinite-carousel/3-row/1@2x.webp 2x" alt="01"/>
                        </div>
                    </div> 

                    <div class="logos4 logo">
                        <div class="logos-slide4">
                            <img class="grid__img4" src="/img/infinite-carousel/4-row/Artboard-1.webp" srcSet="/img/infinite-carousel/4-row/Artboard@2x.webp 2x" alt="01" />
                            <img class="grid__img4" src="/img/infinite-carousel/4-row/Artboard-2.webp" srcSet="/img/infinite-carousel/4-row/Artboard-2-transformed@2x.webp 2x" alt="02" />
                            <img class="grid__img4" src="/img/infinite-carousel/4-row/Artboard-3.webp" srcSet="/img/infinite-carousel/4-row/Artboard-3@2x.webp 2x" alt="03" />
                            <img class="grid__img4" src="/img/infinite-carousel/4-row/Artboard-4.webp" srcSet="/img/infinite-carousel/4-row/Artboard-4@2x.webp 2x" alt="04" />
                            <img class="grid__img4" src="/img/infinite-carousel/4-row/Artboard-5.webp" srcSet="/img/infinite-carousel/4-row/Artboard-5_upscaled@2x.webp 2x" alt="05" />
                            <img class="grid__img4" src="/img/infinite-carousel/4-row/Artboard-6.webp" srcSet="/img/infinite-carousel/4-row/Artboard-6_upscaled@2x.webp 2x" alt="06" />
                            <img class="grid__img4" src="/img/infinite-carousel/4-row/Artboard-2.webp" srcSet="/img/infinite-carousel/4-row/Artboard-2-transformed@2x.webp 2x" alt="01" />
                            <img class="grid__img4" src="/img/infinite-carousel/4-row/Artboard-3.webp" srcSet="/img/infinite-carousel/4-row/Artboard-3@2x.webp 2x" alt="02" />
                        </div>
                    </div>

                    <div class="logos5 logo">
                        <div class="logos-slide5">
                            <img class="grid__img5" src="/img/infinite-carousel/5-row/announcement.webp" srcSet="/img/infinite-carousel/5-row/announcement@2x.webp 2x" alt="01" />
                            <img class="grid__img5" src="/img/infinite-carousel/5-row/Festival.webp" srcSet="/img/infinite-carousel/5-row/Festival@2x.webp 2x" alt="02" />
                            <img class="grid__img5" src="/img/infinite-carousel/5-row/Poll-question.webp" srcSet="/img/infinite-carousel/5-row/Poll-question@2x.webp 2x" alt="03" />
                            <img class="grid__img5" src="/img/infinite-carousel/5-row/Quote.webp" srcSet="/img/infinite-carousel/5-row/Quote@2x.webp 2x" alt="04" />
                            <img class="grid__img5" src="/img/infinite-carousel/5-row/showcase.webp" srcSet="/img/infinite-carousel/5-row/showcase@2x.webp 2x" alt="05" />
                            <img class="grid__img5" src="/img/infinite-carousel/5-row/Testimonial.webp" srcSet="/img/infinite-carousel/5-row/Testimonial@2x.webp 2x" alt="06" />
                            <img class="grid__img5" src="/img/infinite-carousel/5-row/tips.webp" srcSet="/img/infinite-carousel/5-row/tips@2x.webp 2x" alt="07" />
                            <img class="grid__img5" src="/img/infinite-carousel/5-row/Quote.webp" srcSet="/img/infinite-carousel/5-row/Quote@2x.webp 2x" alt="08" />
                        </div>
                    </div>

                    <div class="logos6 logo">
                        <div class="logos-slide6">
                            <img class="grid__img6" src="/img/infinite-carousel/6-row/Artboard-1.webp" srcset="/img/infinite-carousel/6-row/Artboard-1@2x.webp 2x" alt="01" />
                            <img class="grid__img6" src="/img/infinite-carousel/6-row/Artboard-2.webp" srcSet="/img/infinite-carousel/6-row/Artboard-2@2x.webp 2x" alt="02" />
                            <img class="grid__img6" src="/img/infinite-carousel/6-row/Artboard-3.webp" srcSet="/img/infinite-carousel/6-row/Artboard-3@2x.webp 2x" alt="03" />
                            <img class="grid__img6" src="/img/infinite-carousel/6-row/Artboard-4.webp" srcSet="/img/infinite-carousel/6-row/Artboard-4@2x.webp 2x" alt="04" />
                            <img class="grid__img6" src="/img/infinite-carousel/6-row/Artboard-5.webp" srcSet="/img/infinite-carousel/6-row/Artboard-5@2x.webp 2x" alt="05" />
                            <img class="grid__img6" src="/img/infinite-carousel/6-row/Artboard-6.webp" srcSet="/img/infinite-carousel/6-row/Artboard-6@2x.webp 2x" alt="06" />
                            <img class="grid__img6" src="/img/infinite-carousel/6-row/Artboard-7.webp" srcSet="/img/infinite-carousel/6-row/Artboard-7@2x.webp 2x" alt="07" />
                            <img class="grid__img6" src="/img/infinite-carousel/6-row/Artboard-1.webp" srcSet="/img/infinite-carousel/6-row/Artboard-1@2x.webp 2x" alt="08" />
                        </div>
                    </div>
                    </div>
                </div>
        )  
        }


    export default InfiniteCarousel;