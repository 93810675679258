import React, { useEffect } from "react";
import Navbar from "components/Navbar/navbar";
import FullTestimonials from "components/Full-testimonials/full-testimonials";
import Packslider from "components/Packslider/packSlider";
import Slider from "react-slick";
import { GetTestimonialData } from "components/ApiServices/GetTestimonialsData";
import { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingPageForm from "components/LandingPageForm/index";
import LoadingScreen from "components/Loading-Screen";
import InfiniteCarousel from "components/Infinite-carousel";
import StaticTestimonials from "components/Static-testimonials/static-testimonials";
import SEO from "components/seo/seo";
import { StaticImage } from "gatsby-plugin-image";


const OneTimeMarketingPack=()=>{
    const hideLoader=`.pace,.pace-running,pace-done,pace-inactive,pace-active {
        display:none!important;
    }`
    return(
        <>
        <style>{hideLoader}</style>
            <section className="ad-heroSec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="caption center mt-30">
                                <h1 class="animate-charcter animated"><span className="main-head"> Elevate Your Brand's Digital Presence </span><span className="subText"> One-Time Social Media Packages Tailored for Success.</span></h1>
                                <p>Our innovative solutions and expert guidance will fuel your growth journey.</p>
                                <center>
                                <a href="https://calendly.com/evolvan/15min" class="butn curve mt-30 banner-cta">Get free call back</a>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="line left"></div>
            
            <InfiniteCarousel/>
            
            <div className="line right"></div>

            <section className="services box lficon section-padding position-re one-time-pack sub-bg">
                <div className="container">
                    <div className="row justify-content-center ">
                        <div className="col-lg-8 col-md-10">
                            <div className="sec-head  text-center">
                                <h3 className="wow color-font animated">
                                    Our one-time pack includes
                                </h3>
                            </div>
                        </div>
                    </div>

                    <ul id="rev-sec" className="feat mb-30">
                        <li class="wow fadeInUp about-item-box" data-wow-delay=".2s">
                            <img className="icon" src="../../img/pack-info-icons/Group.webp" alt="" />
                            <h6>Social Accounts Creation</h6>
                        </li>

                        <li class="wow fadeInUp about-item-box" data-wow-delay=".2s">
                            <img className="icon" src="/img/pack-info-icons/Frame.webp" alt="" />
                            <h6>Customised Templates</h6>
                        </li>
                    </ul>

                    <ul className="feat mb-30">
                        <li class="wow fadeInUp about-item-box" data-wow-delay=".2s">
                            <img className="icon" src="/img/pack-info-icons/Frame(2).webp" alt="" />
                            <h6>Brand Guideline Creation</h6>
                        </li>

                        <li class="wow fadeInUp about-item-box" data-wow-delay=".2s">
                            <img className="icon" src="/img/pack-info-icons/Frame(1).webp" alt="" />
                            <h6>Social Media Audit</h6>
                        </li>
                    </ul>

                    <ul className="feat mb-30">
                        <li class="wow fadeInUp about-item-box" data-wow-delay=".2s">
                            <img className="icon" src="/img/pack-info-icons/Untitled-2.webp" alt="" />
                            <h6>Event/Festive Creatives</h6>
                        </li>

                        <li class="wow fadeInUp last-about about-item-box" data-wow-delay=".2s">
                            <h6>and many more....</h6>
                        </li>
                    </ul>
                </div>
            </section>

            <div className="line left"></div>

            <section className="services box lficon section-padding position-re why-use-one-time-pack bg-img" style={{backgroundImage:"url(/img/patrn.svg)"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className="sec-head  text-center">
                                <h3 className="wow color-font animated">
                                Why use our one-time pack?
                                </h3>
                            </div>
                        </div>
                    </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 wow fadeInLeft" data-wow-delay=".5s">
                        <div className="item-box no-curve">
                            <div className="cont">
                                <h6>1. Cost-Effective Solution</h6>
                                <p>
                                Our one-time pack offers a cost-effective solution compared to ongoing monthly subscriptions, making it suitable for businesses with budget constraints.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 wow fadeInLeft" data-wow-delay=".5s">
                        <div className="item-box no-curve">
                            <div className="cont">
                                <h6>2. Tailored to Your Needs</h6>
                                <p>
                                Each one-time pack is custom-tailored to your specific business goals, ensuring that you receive targeted solutions that align with your objectives.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 wow fadeInLeft" data-wow-delay=".5s">
                        <div className="item-box no-curve">
                            <div className="cont">
                                <h6>3. Quick Results</h6>
                                <p>
                                With our one-time pack, you can expect to see quick results as we implement strategies designed to boost your online presence efficiently.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 wow fadeInLeft" data-wow-delay=".5s">
                        <div className="item-box no-curve">
                            <div className="cont">
                                <h6>4. No Long-Term Commitment</h6>
                                <p>
                                Unlike long-term contracts, our one-time pack requires no long-term commitment, giving you the flexibility to engage with our services as needed.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 wow fadeInLeft" data-wow-delay=".5s">
                        <div className="item-box no-curve">
                            <div className="cont">
                                <h6>5. Focused Approach</h6>
                                <p>
                                Our one-time pack allows us to focus our efforts on delivering maximum impact within a specific timeframe, ensuring that your goals are met effectively.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 wow fadeInLeft" data-wow-delay=".5s">
                        <div className="item-box no-curve">
                            <div className="cont">
                                <h6>6. Professional Expertise</h6>
                                <p>
                                Benefit from the expertise of our seasoned professionals who will guide you through every step of the process to achieve your desired outcomes.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 wow fadeInLeft" data-wow-delay=".5s">
                        <div className="item-box no-curve">
                            <div className="cont">
                                <h6>7. Branded Online Presence</h6>
                                <p>
                                Establish a strong and consistent branded online presence that resonates with your target audience, driving engagement and loyalty.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 wow fadeInLeft" data-wow-delay=".5s">
                        <div className="item-box no-curve">
                            <div className="cont">
                                <h6>8. Transparent Process</h6>
                                <p>
                                Our transparent process ensures that you are fully informed and involved throughout the duration of the project, providing peace of mind and confidence in the results
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <div className="line left"></div>

            <StaticTestimonials/>

            <div className="line right"></div>
            
            <section className="pricing-table sub-bg">
                <div className="container price-table d-flex align-items-end justify-content-center flex-wrap">
                    <div className="pack-card-container">
                        <center>
                            <img className="pack-icon" src="../img/landing-page/pack-info-iconns/Micro-Icon.webp"/>
                        </center>

                        <div className="pack-card">
                            <div className="price-fetaures">
                                <div className="pricing text-center">
                                    <h4 className="wow color-font animated">Micro <br /> &#x20b9; 5999</h4>
                                </div>

                                <div className="features">
                                    <h6 className="text-center mb-10">Features</h6>
                                    <ul className="list">
                                        <li>3 Social Media Accounts</li>
                                        <li>Social Media Audit</li>
                                        <li>10 Customised Social Media Templates</li>
                                        <li>Brand Guidelines Creation</li>
                                        <li>10 Festival Creatives</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="cont-btn">
                                <center>
                                    <a href="#contact" class="butn curve banner-cta">Contact US</a>
                                </center>
                            </div>
                        </div>
                    </div>

                    <div className="pack-card-container">
                        <center>
                            <img className="pack-icon" src="../img/landing-page/pack-info-iconns/Hatchback-Icon.webp"/>
                        </center>

                        <div className="pack-card">
                            <div className="price-features">
                                <div className="pricing text-center">
                                    <h4 className="wow color-font animated">HATCHBACK <br /> &#x20b9; 9999</h4>
                                </div>
                                <div className="features">
                                    <h6 className="text-center mb-10">Features</h6>
                                    <ul className="list">
                                        <li>Everything in MICRO +</li>
                                        <li>5 Social Media Accounts</li>
                                        <li>20 Customised Social Media Templates</li>
                                        <li>Hashtags Strategy</li>
                                        <li>Competitor Analysis</li>
                                        <li>Interactive Content Creation</li>
                                        <li>20 Festival Creatives</li>
                                    </ul>
                                </div>
                            </div>
 
                            <div className="cont-btn">
                                <center>
                                    <a href="#contact" class="butn curve banner-cta">Contact US</a>
                                </center>
                            </div>
                        </div>
                    </div>

                    <div className="pack-card-container">
                        <center>
                            <img className="pack-icon" src="../img/landing-page/pack-info-iconns/Sedan-Icon.webp"/>
                        </center>
                    <div className="pack-card-parent">
                        <center>
                            <div className="poplr-tag">
                                Most Popular
                            </div>
                        </center>
                        <div className="pack-card">
                            <div className="price-features">
                                <div className="pricing text-center">
                                    <h4 className="wow color-font animated">SEDAN <br /> &#x20b9; 12999</h4>
                                </div>
                                <div className="features">
                                    <h6 className="text-center mb-10">Features</h6>
                                    <ul className="list">
                                        <li>Everything in HATCHBACK +</li>
                                        <li>10 Social Media Accounts</li>
                                        <li>30 Customised Social Media Templates</li>
                                        <li>Engagement Boost Tactics</li>
                                        <li>Influencer Collaboration Strategy</li>
                                        <li>Audience Persona Development</li>
                                        <li>Community Building Tactics</li>
                                        <li>User-Generated Content Campaign Strategy</li>
                                        <li>30 Festival Creatives</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="cont-btn">
                                    <center className="mb-20">
                                        <a href="#contact" class="butn curve banner-cta">Contact US</a>
                                    </center>
                                </div>
                        </div>
                    </div>
                    </div>

                    <div className="pack-card-container">
                        <center>
                            <img className="pack-icon" src="../img/landing-page/pack-info-iconns/SUV-Icon.webp"/>
                        </center>
                    <div className="pack-card">
                        <div className="price-features">
                            <div className="pricing text-center">
                                <h4 className="wow color-font animated">SUV <br /> &#x20b9; 16999</h4>
                            </div>
                            <div className="features">
                                <h6 className="text-center mb-10">Features</h6>
                                <ul className="list">
                                    <li>Everything in SEDAN +</li>
                                    <li>Ad Campaign Recommendation</li>
                                    <li>Employee Advocacy</li>
                                    <li>Live Video Strategy</li>
                                    <li>Geotargeting for Local Businesses</li>
                                    <li>Storytelling Framework</li>
                                </ul>
                            </div>
                        </div>
 
                        <div className="cont-btn">
                            <center>
                                <a href="#contact" class="butn curve banner-cta">Contact US</a>
                            </center>
                        </div>
                        </div>
                    </div>    
                </div>
            </section>

            <div className="line left"></div>

            <LandingPageForm/>

            <div className="line right"></div>

            <section className="services position-re call-action section-padding sub-bg process">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className="sec-head  text-center">
                                <h6 className="wow fadeIn" data-wow-delay=".5s">How it works</h6>
                                <h3 className="wow color-font animated">Our One-Time Pack Process</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInUp d-flex ">
                            <div className="step-item">
                                <h3 className="icon usp-icon">1</h3>
                                <h6>Express Interest</h6>
                                <p>Show interest by filling out our contact form or reaching out to us directly. We'll promptly get in touch with you to discuss your needs and answer any questions you may have.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInUp d-flex ">
                            <div className="step-item">
                                <h3 className="icon usp-icon">2</h3>
                                <h6>Package Selection</h6>
                                <p>Choose the package that best suits your requirements from our range of options. Whether you're looking for basic setup or a comprehensive solution, we have packages to fit every need.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInUp d-flex ">
                            <div className="step-item">
                                <h3 className="icon usp-icon">3</h3>
                                <h6>Payment</h6>
                                <p>Once you've selected your package, proceed to make the payment securely through our online platform. Rest assured, your transaction is safe and hassle-free.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInUp d-flex ">
                            <div className="step-item">
                                <h3 className="icon usp-icon">4</h3>
                                <h6>Strategy Development</h6>
                                <p>With payment confirmed, our team will kickstart the strategy development process. We'll analyze your business, target audience, and competition to create a tailored plan for success.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6  wow fadeInUp d-flex ">
                            <div className="step-item">
                                <h3 className="icon usp-icon">5</h3>
                                <h6>Brand Guideline Approval</h6>
                                <p>Before we proceed further, we'll present you with brand guidelines and two sample designs for your approval. This ensures that our vision aligns with your expectations and brand identity.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp d-flex ">
                            <div className="step-item">
                                <h3 className="icon usp-icon">6</h3>
                                <h6>Delivery</h6>
                                <p>Once the brand guidelines are approved, we'll get to work implementing the agreed-upon strategy. Depending on your package, we'll deliver the specified services within the designated timeframe.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="line right"></div>

            <section className="clients min-area">
                <div className="content">
                    <h4 class="fit-width mx-auto wow color-font animated mb-0">Our Trusted Clients</h4>
                </div>
                    <center>
                    <div class="logo-slide">
                        <img class="grid__img1" src="/img/clients-logo/ABC.webp" alt="01" />
                        <img class="grid__img1" src="/img/clients-logo/Aura-Finance.webp" alt="021" />
                        <img class="grid__img1" src="/img/clients-logo/GK.webp" alt="03" />
                        <img class="grid__img1" src="/img/clients-logo/kanhiya-ads.webp" alt="042" />
                        <img class="grid__img1" src="/img/clients-logo/Mindfull-eating.webp" alt="011" />
                        <img class="grid__img1" src="/img/clients-logo/Shaxify.webp" alt="02" />
                    </div>
                    </center>
            </section>

            <div className="line left"></div>

            <section style={{backgroundImage:'url(/img/patrn.svg)'}} className="adfaq-sec bg-img call-action min-area">
                <div className="content">
                    <h4 className="text-center wow color-font">FAQs</h4>
                </div>
                <div class="accordion container" id="accordionExample">
                    <div className="row">
                        <div className="col-md-6">
                            <div class="card">
                            <div class="card-header flex-column align-items-start" id="headingOne">
                            <h2 class="mb-0">
                                <span class="btn btn-link btn-block text-left ellipsis" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What exactly is included in your one-time pack?
                                </span>
                            </h2>
                            <hr className="seprator"/>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body">
                            Our one-time pack includes a comprehensive range of services tailored to your specific needs, such as social media account setup, customized templates, content strategy development, competitor analysis, and more. Each pack is customized to deliver maximum impact for your business.
                            </div>
                            </div>
                            </div>

                            <div class="card">
                                <div class="card-header flex-column align-items-start" id="headingTwo">
                                    <h2 class="mb-0">
                                        <span class="btn btn-link btn-block text-left collapsed ellipsis" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        How long does it take to see results from your one-time pack?
                                        </span>
                                    </h2>
                                    <hr className="seprator"/>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                The timeline for seeing results can vary depending on factors such as your industry, target audience, and the specific goals of your campaign. However, we strive to deliver quick and tangible results within a reasonable timeframe, typically ranging from a few weeks to a few months.
                                </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header flex-column align-items-start" id="headingThree">
                                <h2 class="mb-0">
                                    <span class="btn btn-link btn-block text-left collapsed ellipsis" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Is there a minimum contract period for your one-time pack?
                                    </span>
                                </h2>
                                <hr className="seprator"/>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                No, there is no minimum contract period for our one-time pack. It is designed to offer flexibility and convenience without the need for a long-term commitment. You can engage with our services as needed, without any obligations beyond the completion of the agreed-upon project.
                                {/* <table class="table w-50">
                                    <tbody >
                                        <tr>
                                            <th scope="col">Micro Pack:</th>
                                            <td>₹5999 INR</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Hatchback Pack:</th>
                                            <td>₹9999 INR</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Sedan Pack:</th>
                                            <td>₹16999 INR</td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <p>For pricing on our SUV pack, please contact us directly. Each pack is tailored to suit different needs and budgets, providing a range of options to accommodate various requirements.</p> */}
                                </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header flex-column align-items-start" id="headingFour">
                                    <h2 class="mb-0">
                                        <span class="btn btn-link btn-block text-left collapsed ellipsis" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        How do you ensure that the services provided in the one-time pack are tailored to my business?
                                        </span>
                                    </h2>
                                    <hr className="seprator "/>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                We take a personalized approach to every project, beginning with a thorough consultation to understand your business objectives, target audience, and unique challenges. Based on this information, we customize our services to align perfectly with your needs, ensuring optimal results.
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div class="card">
                                <div class="card-header flex-column align-items-start" id="headingFive">
                                    <h2 class="mb-0">
                                        <span class="btn btn-link btn-block text-left collapsed ellipsis" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Can I upgrade to ongoing services after using your one-time pack?
                                        </span>
                                    </h2>
                                    <hr className="seprator"/>
                                </div>
                                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                Absolutely! Our one-time pack serves as an excellent introduction to our services and can seamlessly transition into ongoing support if desired. We offer a range of flexible options for continued growth and success beyond the initial project.
                                </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header flex-column align-items-start" id="headingSix">
                                    <h2 class="mb-0">
                                        <span class="btn btn-link btn-block text-left collapsed ellipsis" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        Are revisions allowed after the approval of the brand guidelines?
                                        </span>
                                    </h2>
                                    <hr className="seprator"/>
                                </div>
                                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                <div class="card-body">
                                No, revisions are not permitted once the brand guidelines have been approved. We ensure meticulous attention to detail during the brand guideline approval process to align with your vision and requirements effectively.
                                </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header flex-column align-items-start" id="headingSeven">
                                    <h2 class="mb-0">
                                        <span class="btn btn-link btn-block text-left collapsed ellipsis" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                        Can I get a refund if I'm not satisfied with the services provided?
                                        </span>
                                    </h2>
                                    <hr className="seprator"/>
                                </div>
                                <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                <div class="card-body">
                                Yes, you are eligible for a 100% refund at any step of the workflow, except once the Brand Guideline is approved. We want to ensure your satisfaction, and if you're not happy with our services for any reason, simply contact us, and we'll initiate the refund process promptly.
                                </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header flex-column align-items-start" id="headingSeven">
                                    <h2 class="mb-0">
                                        <span class="btn btn-link btn-block text-left collapsed ellipsis" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                        What formats will the templates and creatives be delivered in?
                                        </span>
                                    </h2>
                                    <hr className="seprator"/>
                                </div>
                                <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                <div class="card-body">
                                Our templates will be provided in editable PDF format and Canva designs, allowing you the flexibility to customize them according to your needs. Other creatives, such as graphics and visuals, will be delivered in PNG format for easy integration into your digital platforms.
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="py-5">
                <p className="text-center">&copy; {new Date().getFullYear()} EVOLVAN INFO SOLUTIONS PVT LTD. All rights reserved.</p>
            </footer>
        </>
    );
};

export const Head=()=>{

    return(
        <SEO ogimage="https://staging.evolvan.com/img/landing-page/meta-images/Elevate-Your-Brand-s-Digital-Presence-One-Time-Social-Media-Packages-Tailored-for-Success.png"
        title="Elevate Your Brand's Digital Presence: One-Time Social Media Packages Tailored for Success"
        twitterimage="https://staging.evolvan.com/img/landing-page/meta-images/Elevate-Your-Brand-s-Digital-Presence-One-Time-Social-Media-Packages-Tailored-for-Success.png"
        />
    );
};

export default OneTimeMarketingPack;
